body {
  margin: 0;
  padding-top: 55px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.socialshare {
  margin-left: 10px;
}

img.btn_wa {
  margin-top: 10px;
  border-radius: 10%;
  width: "67px";
  height: "20px";
}

.btn_tweet {
  margin-top: 10px;
}

.btn_fb {
  margin-top: 6px;
}

.nounderline {
  text-decoration: none !important;
  color:#000000;
}

.hoverDiv {
  background-color: #f7f7f7;
}

.hoverDiv:hover {
  background-color: #d3e9f0;
}

.table-row{
  cursor:pointer;
}

.blank_row
{
    height: 5px !important; /* overwrites any other rules */
}
  

@media screen and (max-width: 600px) {
  div.responsive {
    font-size: 70%;
  }
}

@media screen and (max-width: 330px) {
  div.responsive {
    font-size: 60%;
  }
}

@media screen and (max-height: 700px) {
.dropdown-menu {
  max-height:350px;
  overflow-y:scroll;
  }
}

img.profile {
  border-radius: 50%;
  padding: 3px;
}

.ferryCard {
  position: relative;
}

.favoriteButton {
  position: absolute;
  bottom: 5px;
  right: 6px;
}

.favoriteHeart {
  color: #d50e0e;
  font-size: 140%;
}

.favoriteHeartsm {
  color: #d50e0e;
  font-size: 60%;
}


.card {
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  background-color: #FFFFFF;
 
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  
}



.bgimg-1, .bgimg-2, .bgimg-3, .bgimg-4 {
  position: relative;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}
.bgimg-1 {
  background-image: url("/images/bg1.jpg");
  opacity: 0.65;
  min-height: 100%;
  height: 700px;
}

.bgimg-2 {
  background-image: url("/images/bg3.jpg");
  opacity: 0.75;
  max-height: 250px;
  height: 700px;
}

.bgimg-3 {
  background-image: url("/images/bg2.jpg");
  opacity: 0.75;
  max-height: 250px;
  height: 700px;
}

.bgimg-4 {
  background-image: url("/images/bg4.jpg");
  opacity: 0.75;
  max-height: 300px;
  height: 400px;
}

.abtcaption {
  position: absolute;
  left: 0;
  top: 20%;
  width: 100%;
  text-align: center;
  color: #000;
}

.abtcaption span.border {
  background-color: #111;
  color: #fff;
  padding: 18px;
  letter-spacing: 5px;
}

.abttoc {
  position: relative;
  left: 0;
  top: 55px;
  width: 100%;
  text-align: center;
  color: #070019
}

.abttoc span.border {
  background-color: rgb(5, 46, 159);
  color: #fff;
  padding: 8px;
  letter-spacing: 1px;
}

.abtbody {
  color: #777;
  background-color: #fff;
  text-align: center;
  padding: 20px 15px;
  text-align: justify;
  z-index: 9;
}

.abttitle {
letter-spacing: 5px;
padding-top: 55px;
text-transform: uppercase;
color:#111;
text-align: center;
}

a.abtanchor {
color: #FFFFFF;
text-decoration: underline;
}

a.abtanchor:hover {
  color: #d50e0e
}

/* Turn off parallax scrolling for tablets and phones */
@media only screen and (max-device-width: 1024px) {
  .bgimg-1, .bgimg-2, .bgimg-3, .bgimg-4 {
    background-attachment: scroll;
  }
}